import React from 'react';
import Layout from '../components/Layout';
import SeoPage from '../components/SEO/SeoPage';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';

import { graphql } from 'gatsby';
import AuthorPosts from '../components/AuthorPosts';
import Avatar from '../components/Avatar';

export default function AuthorTemplate({ data }) {
  const { author } = data;
  let { totalArticles } = data;

  //let author = authors.filter(author => author.id === id);
  //author = author[0];
  const theme = useTheme();

  return (
    <Layout>
      <SeoPage
        title={`${author.data.name.text} Artículos`}
        description={`${author.data.name.text} Artículos de DeDiabetes`}
      />
      <main className="text-center mt-5">
        <Avatar
          authorPic={author.data.headshot.gatsbyImageData}
          authorName={author.data.name.text}
          authorpage
        />
        <h1>
          {author.data.name.text}, {author.data.certifications}
        </h1>

        <div>
          {author.data.linkedin.raw.url ? (
            <a
              href={author.data.linkedin.raw.url}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                aria-label={`${author.data.name.text} LinkedIn`}
                style={{
                  color: theme.palette.info.main,
                }}
              >
                <LinkedInIcon />
              </IconButton>
            </a>
          ) : (
            ''
          )}
          {author.data.facebook.raw.url ? (
            <a
              href={author.data.facebook.raw.url}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                aria-label={`${author.data.name.text} Facebook`}
                style={{
                  color: theme.palette.info.main,
                }}
              >
                <FacebookIcon />
              </IconButton>
            </a>
          ) : (
            ''
          )}
          {author.data.twitter.raw.url ? (
            <a
              href={author.data.twitter.raw.url}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                aria-label={`${author.data.name.text} Twitter`}
                style={{
                  color: theme.palette.info.main,
                }}
              >
                <TwitterIcon />
              </IconButton>
            </a>
          ) : (
            ''
          )}
          {author['personalink'] ? (
            <a href={author['personalink']} target="_blank" rel="noreferrer">
              <IconButton
                aria-label={`${author.data.name.text} personal website`}
                style={{
                  color: theme.palette.info.main,
                }}
              >
                <LaunchIcon />
              </IconButton>
            </a>
          ) : (
            ''
          )}
        </div>
        <p className="max-w-xl mx-auto mt-2">
          {author.data.description_long.text}
        </p>
      </main>
      <section
        className="p-10 text-center mt-8"
        style={{
          background:
            'linear-gradient(to bottom, rgb(229, 231, 235) 16%, #fff 16%, #fff 100%)',
        }}
      >
        <p
          className="border-b-2 border-solid inline-block"
          style={{ borderColor: '#5a5759' }}
        >
          Artículos de {author.data.name.text} ({totalArticles.edges.length})
        </p>

        <AuthorPosts totalArticles={totalArticles} />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query authorQuery($slug: String) {
    author: prismicAuthor(uid: { eq: $slug }) {
      uid
      data {
        name {
          text
        }
        certifications
        description_short {
          text
        }
        description_long {
          text
        }
        facebook {
          raw
        }
        linkedin {
          raw
        }
        twitter {
          raw
        }
        headshot {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
    }
    totalArticles: allPrismicBlogPost(
      filter: { data: { author: { uid: { eq: $slug } } } }
    ) {
      edges {
        node {
          slug: uid
          data {
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`;
