import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"


const Avatar = ({ authorPic, authorName, authorpage }) => {

    if (authorpage) {
        return (
            <div
              className="rounded-full overflow-hidden mx-auto mb-4"
              style={{ width: '200px', height: '200px' }}
            >
              <GatsbyImage
                          image={authorPic}
                          className="object-cover object-center w-full h-full"
                          alt={authorName}
                        />
            </div>
          );

    } else
        return (
            <div
        className="border-4 border-themeBrandColor rounded-full overflow-hidden shadow-inner mr-2"
        style={{ width: '48px', height: '48px', minWidth: '48px' }}
      >
            <GatsbyImage
                image={authorPic}
                className="object-cover object-center w-full h-full"
                alt={authorName}
            />
      </div>
    );
    
  };

export default Avatar;